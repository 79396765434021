<template>
	<div class="flex h-screen max-h-screen" :class="{ startup: !started }">
		<div class="overflow-hidden transition-all duration-700 left-panel bg-cover bg-center relative" :class="{ 'choozr-gradient': typeof store?.background !== 'object' }" :style="{ backgroundImage: storeBgStyle }">
			<div class="items-center flex flex-col gap-8 p-9 content-center place-content-center h-full">
				<template v-if="store">
					<img v-if="store.logo" :src="store.logo" :alt="store.name" class="w-full max-w-xs h-auto" />
					<h2 v-else-if="store.name" class="text-4xl" :class="`text-${storeForeground}`">{{ store.name }}</h2>
					<svg v-if="store.logo || store.name" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="w-9 mt-4"
						:class="`stroke-${storeForeground}`">
						<line x1="0" y1="0" x2="64" y2="64" stroke-width="2" />
						<line x1="64" y1="0" x2="0" y2="64" stroke-width="2" />
					</svg>
				</template>
				<ChoozrLogo class="max-w-xs" :class="`fill-${storeForeground}`"></ChoozrLogo>
				<footer class="w-full text-center text-xxs opacity-40 absolute bottom-5 left-0 right-0 line-clamp-3 overflow-ellipsis" :class="`text-${storeForeground}`">Version {{ versionText }}<br/>&copy; Choozr Group Ltd<br /></footer>
			</div>
		</div>
		<div class="main flex-grow relative transition-all duration-700 fade-in pt-5 sm:pt-0" :class="{ 'pt-8': (currentPage != 3) }" id="main-view">
			<!-- <div v-if="currentPage != 3" class="absolute top-5 left-0 right-0 flex justify-center items-center pt-5">
				<ol class="flex space-x-2">
					<li :class="currentPage >= 0 ? 'bg-graphite-black' : 'border border-graphite-black'" class="w-2 h-2 rounded-full focus:outline-none focus:ring focus:ring-offset-2 focus:ring-graphite-black" aria-label="QR Code page"></li>
					<li :class="currentPage >= 1 ? 'bg-graphite-black' : 'border border-graphite-black'" class="w-2 h-2 rounded-full focus:outline-none focus:ring focus:ring-offset-2 focus:ring-graphite-black" aria-label="Pin Code page"></li>
					<li :class="currentPage >= 2 ? 'bg-graphite-black' : 'border border-graphite-black'" class="w-2 h-2 rounded-full focus:outline-none focus:ring focus:ring-offset-2 focus:ring-graphite-black" aria-label="Processing page"></li>
					<li :class="currentPage >= 3 ? 'bg-graphite-black' : 'border border-graphite-black'" class="w-2 h-2 rounded-full focus:outline-none focus:ring focus:ring-offset-2 focus:ring-graphite-black" aria-label="Size Recommendation page"></li>
				</ol>
			</div> -->
			<RouterView />
		</div>
	</div>
			<!-- Top Right Menu with Close and Dropdown options -->

	<div class="absolute top-0 right-0 z-50 flex flex-row">
            <!-- Hamburger Menu Icon with Dropdown options -->
            <button @click="dropdownVisible = !dropdownVisible" class="appearance-none block py-4 px-1 text-dark-gray hover:text-black">
                <font-awesome-icon :icon="['fas', 'ellipsis']" />
            </button>
            <div v-if="dropdownVisible" v-on-click-outside="closeDropdown" class="bg-white shadow-md rounded-xl overflow-hidden text-graphite-black text-left w-48 absolute top-12 dropdown">
                <a href="https://legal.tailor.guide/privacy-policy" target="_blank" @click="dropdownVisible = false" class="dropdown-option">{{ $t("Privacy Policy") }}</a>
                <a href="https://legal.tailor.guide/tos" target="_blank" @click="dropdownVisible = false" class="dropdown-option">{{ $t("Terms of Service") }}</a>
                <button class="dropdown-option" @click="feedback">{{ $t("Leave feedback") }}</button>
                <button class="dropdown-option" @click="bug">{{ $t("Report a bug") }}</button>
                <a href="https://choozr.ai/?ref=sizeguide" target="_blank" @click="dropdownVisible = false" class="dropdown-option">{{ $t("About") }}</a>
                <button @click="logoutAndClose" class="dropdown-option">{{ $t("Log out") }}</button>
            </div>

            <!-- Close Icon -->
            <button @click="close" class="appearance-none block p-5 text-dark-gray hover:text-black">
                <font-awesome-icon :icon="['fas', 'close']" />
            </button>
	</div>
</template>

<script setup lang="ts">
// @ts-expect-error
import ChoozrLogo from "./assets/choozr.svg?component";
import { ref, onMounted, onUnmounted, computed, nextTick, Ref } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import { useRoute } from "vue-router";
import { useLogger } from "vue-logger-plugin";
import { Authentication } from "./services/authentication";
import { version } from "../package.json";
import globals from "./globals";
import * as Sentry from "@sentry/browser";
import { useI18n } from "vue-i18n";
const { t } = useI18n({});

const log = useLogger();

const dropdownVisible = ref(false);
const route = useRoute();
const started = ref(false);
const versionText = ref(version);
interface Store {
  logo?: string;
  name?: string;
  foreground?: "black" | "white";
  background?: {
    type: 'css' | 'image';
    value: string;
  };
}

function getContrast(hexColor: string) {
	console.log({ hexColor });
    // Remove the hash sign if it exists
    if (hexColor.charAt(0) === '#') {
        hexColor = hexColor.substr(1);
    }

    // Convert the hex color to RGB values
    const r = parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);

    // Calculate the relative luminance of the color
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Choose "dark" or "light" depending on the luminance
    return luminance > 0.5 ? 'white' : 'black';
}

const storeConfig: Store = {};
if(globals.options.backgroundImage) storeConfig.background = { type: "image", value: globals.options.backgroundImage };
if(globals.options.brandLogo) storeConfig.logo = globals.options.brandLogo;
if(globals.options.brandName) storeConfig.name = globals.options.brandName;
if(globals.options.fontColor) storeConfig.foreground = getContrast(globals.options.fontColor);

const store: Ref<Store> = ref<Store>(storeConfig
	//{
	// logo: "https://pic.useful.fi/j6moC7J4X.svg",
	// name: "Your Brand",
	// foreground: "black",
	// background: {
	// 	type: "css",
	// 	value: "linear-gradient(90deg, #bada55 0%, #bada11 100%)",
	// },
	// background: {
	// 	type: "image",
	// 	value: "https://pic.useful.fi/VghyEq7Mb.png",
	// },
	//}
);

const currentPage = computed(() => {
	return route.path === "/" || route.path === "/ssoqr" ? 0 : route.path === "/pin" || route.path === "/ssopin" ? 1 : route.path === "/process" ? 2 : route.path === "/size-recommendation" || route.path === "/made-to-measure" ? 3 : -1;
});

const storeBgStyle = computed(() => {
	if (store.value.background?.type === "css") return store.value.background.value;
	if (store.value.background?.type === "image") return `url(${store.value.background.value})`;
});

const storeForeground = computed(() => (store.value.foreground === "black") ? "black" : "white");

const closeDropdown = () => {
	dropdownVisible.value = false;
};

const cascadeClose = (type: "close" | "logout" = "close") => {
	try {
		window.parent.postMessage(
			{
				type: "close",
				data: {
					type,
				},
			},
			"*"
		);
	} catch (e) {
		log.error(e);
	}
}

const bug = () => {
	closeDropdown();
	const eventId = Sentry.captureMessage("Bug Report");
	Sentry.showReportDialog({  title: t("Bug Report") });
}

const feedback = () => {
	closeDropdown();
	const eventId = Sentry.captureMessage("User Feedback");
	Sentry.showReportDialog({ eventId, title: t("User Feedback"), subtitle: t("Please let us know what you think about the app and how we could improve it."), labelComments: t("Your feedback"), labelSubmit: t("Submit") });
}

const close = () => {
	log.debug("Closing");
	cascadeClose("close");
	window.close();
	closeDropdown();
	setTimeout(afterClose, 1000);
};

const logoutAndClose = () => {
	log.debug("Logging out and closing");
	Authentication.logout();
	cascadeClose("logout");
	window.close();
	closeDropdown();
	setTimeout(afterClose, 3000);
};

const afterClose = () => {
	log.debug("After close");
	alert(t("This window was opened from another window and we were not able to automatically close it. It is safe to close this window or tab manually now."));
};

const onEscKeyDown = (event: KeyboardEvent) => {
	if (event.key === "Escape") {
		closeDropdown();
	}
};

onMounted(() => {
	window.addEventListener("keydown", onEscKeyDown);
	setTimeout(async () => {
		await nextTick();
		started.value = true;
	}, 300);
});

onUnmounted(() => {
	window.removeEventListener("keydown", onEscKeyDown);
});
</script>

<style lang="scss">
:not(.startup) .left-panel {
	@apply w-[36%] max-lg:w-0;
}

.startup .left-panel {
	@apply w-full;
}

:not(.startup) #main-view {
	@apply w-0 overflow-hidden;
}
.dropdown-option {
    @apply block w-full text-left px-4 py-3 hover:bg-light-gray font-normal;
}
</style>

<style scoped>
.dropdown {
	right: 50px;
	right: calc(100% - 50px);
}
</style>
