import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { Authentication } from "./services/authentication";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "QRCode",
    meta: {
      jwt: false,
      hack: true,
    },
    component: () => import("./views/QRCode.vue"),
  },
  {
    path: "/pin",
    name: "Pin",
    meta: {
      jwt: false,
      hack: true,
    },
    component: () => import("./views/PinCode.vue"),
  },
  {
    path: "/process",
    name: "Process",
    meta: {
      jwt: true
    },
    component: () => import("./views/Process.vue"),
  },
  {
    path: "/size-recommendation",
    name: "SizeRecommendation",
    meta: {
      jwt: true
    },
    component: () => import("./views/Recommendation.vue"),
  },
  {
    path: "/made-to-measure/:type?",
    name: "Made2Measure",
    meta: {
      jwt: true
    },
    component: () => import("./views/Made2Measure.vue"),
  },
  {
    path: "/deep/:dynamic?",
    name: "DeepLink",
    component: () => import("./views/Deep.vue")
  },
  {
    path: "/ssoqr",
    name: "SsoQRCode",
    component: () => import("./views/SsoQRCode.vue")
  },
  {
    path: "/ssopin",
    name: "SsoPin",
    component: () => import("./views/SsoPinCode.vue")
  },
  {
    path: "/invitation",
    name: "Invitation",
    component: () => import("./views/InviteQRCode.vue")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard to preserve URL search parameters
router.beforeEach((to, from, next) => {
  const newQuery = from.query;

  if (to.query) {
    Object.assign(newQuery, to.query);
  }

  // Check if the new query parameters are different from the target route's query parameters
  const isNewQueryDifferent = Object.keys(newQuery).some(
    (key) => newQuery[key] !== to.query[key]
  );

  // If the new query parameters are different, call next() with the updated query parameters
  // Otherwise, call next() without modifications to prevent infinite redirect
  if (isNewQueryDifferent) {
    next({ ...to, query: newQuery, replace: true });
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  const jwt = Authentication.getJwt();

  if (to.path === "/ssoqr" && typeof jwt === "string") {
    router.push("/process");
  }

  if(to.meta.hack === true && (from.query.f === "2" || from.query.f === "3")) {
    console.log("Bypass");
  } else if (to.meta.jwt === true && jwt === null && to.query.type !== "proxy") {
    // JWT is required and don't have it, fuck off
    router.push("/");
  } else if (to.meta.jwt === false && jwt !== null) {
        // JWT is not allowed, but we have it, fuck off

        router.push("/process");
  }
  next();
});


export default router;
