import { createI18n } from "vue-i18n";
import messages from "@intlify/unplugin-vue-i18n/messages";
import allLocales from "./locales.json";
import global from "./globals";

// Create Vue I18n instance.
const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: "en_US",
    fallbackLocale: "en_US",
    messages,
});

let userLocale = "en_US";
const lookupLangs = [];

if(global.options.languageCode) {
    lookupLangs.push(global.options.languageCode);
}

if (navigator.languages) {
    lookupLangs.push(...navigator.languages);
} else {
    lookupLangs.push(navigator.language);
}

for (let lang of lookupLangs) {
    const langPrefix = lang.slice(0, 2);
    console.log({ langPrefix })
    const languageMatch = allLocales.find(locale => locale.startsWith(langPrefix)); // TODO: handle zh_CN and zh_TW and other lang cases without relying on the lang alone
    console.log({ languageMatch })
    if (languageMatch) {
        userLocale = languageMatch;
        break
    }
}
if (userLocale !== "en_US") {
    setLocale(userLocale);
}

// If no match is found, default to "en_US"
if (!userLocale) {
    userLocale = "en_US";
}

// Set new locale.
async function setLocale(locale: string) {
    console.log("Setting locale to", locale);
    // Load locale if not available yet.
    if (!i18n.global.availableLocales.includes(locale)) {
        const messages = await loadLocale(locale);

        // fetch() error occurred.
        if (messages === undefined) {
            return;
        }

        // Add locale.
        i18n.global.setLocaleMessage(locale, messages);
    }

    // Set locale.
    i18n.global.locale.value = locale;
}

// Fetch locale.
function loadLocale(locale: string) {
    return fetch(`./locales/${locale}.json`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error("Something went wrong!");
        })
        .catch((error) => {
            console.error(error);
        });
}

export { i18n, allLocales, setLocale };