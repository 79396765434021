import { createApp } from "vue";
import "./style.scss";
import App from "./App.vue";
import { createLogger } from "vue-logger-plugin";
import router from "./router";
import "@fontsource/inter/300.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faTriangleExclamation, faClose, faEllipsis, faCircleNotch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import { Stores, choozrDB } from "./services/db";
import globals from "./globals";

import { i18n } from "./i18n";

import * as Sentry from "@sentry/vue";

library.add(faCircleCheck, faCircleXmark, faTriangleExclamation, faClose, faEllipsis, faCircleNotch, faSpinner);

// TODO: first we have to check our localstorage for a JWT
// Use the auth library here to check if it has expired?
// Redirect to size recommendation if everything is ok
// Start the auth flow if now

// try {
// 	const newStore: Stores = {
// 		store,
// 		hostnames: [hostname],
// 		permissions: ["recommendation"],
// 	};
// 	await choozrDB.permissions.add(newStore);
// 	console.log("Store added successfully");
// } catch (error) {
// 	console.error("Error adding store:", error);
// }

// try {
// 	const storeObj = await choozrDB.permissions.get({ store });
// 	console.log("Store:", storeObj);
// } catch (error) {
// 	console.error("Error fetching store:", error);
// }

type SetupOnceFunction = ((fromConfig: boolean) => void) & { called?: boolean };

const setup: SetupOnceFunction = (fromConfig = false) => {
	if (setup.called) return;
	setup.called = true;
	console.log("Setup fromConfig", fromConfig);
	const app = createApp(App);

	Sentry.init({
		app,
		dsn: "https://f336019bc663187736bae1214e500951@sentry.tailor.guide/2",
		
		tracePropagationTargets: ["localhost", /^https:\/\/localhost:5173\//, /^https:\/\/(app|imagewear)\.choozr\.ai\//, /^https:\/\/choozr-app-dev\.web\.app\//],
		integrations: [
		  new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			// tracingOrigins: [/^\//]
		  }),
		  new Sentry.Replay(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
	app.component("font-awesome-icon", FontAwesomeIcon);
	app
		.use(router)
		.use(
			createLogger({
				enabled: true,
				level: import.meta.env.MODE === "development" ? "debug" : "error",
				callerInfo: import.meta.env.MODE === "development",
			})
		)
		.use(i18n)
		.mount("#app");	
}

let timeout: any;

function handleMessage(event: any) {
	console.log("handleMessage inside iframe", event);
	// TODO: Check if the message is from the expected origin

	// Ensure the message data is an object and has the 'type' property
	if (
	  typeof event.data === "object" &&
	  event.data.hasOwnProperty("type")
	) {
	  switch (event.data.type) {
		case "config":
		  console.log("Received config in sizeguide", event.data);
		  if (typeof event.data.data?.product === "object") {
			globals.product = event.data.data.product;
		  } else {
			console.error(
			  "The product information sent from the integration was malformed"
			);
		  }
		  if (typeof event.data.data?.options === "object") {
			globals.options = event.data.data.options;
		  } else {
			console.error(
			  "The options sent from the integration was malformed"
			);
		  }
		  clearTimeout(timeout);
		  setup(true);
		  break;
	  }
	}
  }

window.addEventListener("message", handleMessage);
setTimeout(setup, 100);
window.parent.postMessage({ type: "requestConfig",  data: {}}, "*");


// (app as any).$log.debug('Test Message');
