import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { jwtDecode, JwtPayload } from "jwt-decode";

interface DeepTokenResponse {
	token: string;
}

interface AuthRefreshResponse {
	jwt: string;
}

interface DeepStatusResponse {
	stage: "created" | "waiting-pin" | "authenticated";
	jwt?: string;
	pin?: string;
}

interface SsoResponse {
	success: boolean;
	jwt: string;
}

interface DeepConfirmResponse {
	success: string;
	message: string;
}

interface InviteResponse {
	success: boolean;
	email: string;
	storeId: string;
	customerName: string;
	firstname: string;
	lastname: string;
	measurementRequestId: string;
}

class AuthenticationClient {
	private api: AxiosInstance;
	private token: string | null = null;
	private jwt: string | null = null;
	private pin: string | null = null;
	private hooks: Function[] = [];
	private tokenErrorHandler: Function | null = null;
	private interval: number | null = null;

	constructor() {
		let jwt = null;
		try {
			jwt = window.localStorage.getItem("jwt");
		} catch (e) {
			console.warn("SecurityError: localStorage is not accessible, ignoring");
		}
		if (jwt !== null && jwt !== "null" && jwt !== "") {
			this.jwt = jwt;
		}
		this.api = axios.create({
			baseURL: import.meta.env.VITE_AUTH_API as string,
		});
	}

	private handleError(error: AxiosError): Promise<any> {
		if (error.response) {
			return Promise.reject(error.response.data);
		} else if (error.request) {
			return Promise.reject("Request error");
		} else {
			return Promise.reject("Network error");
		}
	}

	public isTokenExpiring(): boolean {
		try {
			if (this.jwt === null) throw new Error("Token is missing");
			let decoded: JwtPayload | null;
			try {
				decoded = jwtDecode(this.jwt);
			} catch (e) {
				console.error(e);
				decoded = null;
			}
			if (decoded === null) throw new Error("Invalid token");
			if (typeof decoded !== "object") throw new Error("Invalid token");

			const currentTime = Math.floor(Date.now() / 1000);
			if (decoded.exp === undefined) throw new Error("Invalid token");
			if (currentTime >= decoded.exp) throw new Error("Token has already expired");

			const safetyMargin = 24 * 60 * 60; // 24 hours
			return decoded.exp - currentTime <= safetyMargin;
		} catch (error) {
			window.localStorage.removeItem("jwt");
			throw error;
		}
	}

	public addHook(hook: Function) {
		this.hooks.push(hook);
	}

	public removeHooks() {
		this.hooks = [];
		try {
			clearInterval(this.interval as number);
		} catch (intervalErr) {
			console.error(intervalErr);
		}
	}

	public tokenError(handler: Function) {
		this.tokenErrorHandler = handler;
	}

	public async refreshToken() {
		try {
			const response: AxiosResponse<AuthRefreshResponse> = await this.api.post("/auth/refresh",
				{},
				{
					headers: {
						Authorization: `Bearer ${this.jwt}`
					}
				});
			this.jwt = response.data.jwt;
			window.localStorage.setItem("jwt", this.jwt);
			return this.jwt;
		} catch (error) {
			return this.handleError(error as AxiosError);
		}
	}

	public async getToken(userAgent?: string): Promise<string> {
		try {
			console.log("inside getToken");
			clearInterval(this.interval as number);
			this.interval = null;
			const queryParams = new URLSearchParams();
			if (typeof userAgent === "string") {
				queryParams.set("ua", userAgent);
			}
			const tokenUrl = `/auth/deep/token${queryParams.toString().length > 0 ? `?${queryParams.toString()}` : ""}`;
			const response: AxiosResponse<DeepTokenResponse> = await this.api.get(tokenUrl);
			this.token = response.data.token;
			console.log("Setting interval for updateStatus");
			this.interval = setInterval(this.updateStatus(this, (typeof userAgent === "string" ? userAgent : undefined)), 3000);
			console.log("interval set", this.interval);
			return this.token;
		} catch (error) {
			return this.handleError(error as AxiosError);
		}
	}

	public async updateMeta(meta: object): Promise<void> {
		try {
			await this.api.post("/auth/deep/meta", { token: this.token, meta });
			return;
		} catch (error) {
			return this.handleError(error as AxiosError);
		}
	}

	private updateStatus(ctx: any, userAgent?: string): any {
		console.log("updateStatus called with context", {ctx});
		return () => {
			console.log("Inside the returned function");
			try {
				console.log("Calling getStatus");
				ctx.getStatus(userAgent).then((status: string) => {
					console.log("got status", status);
					for (const hook of ctx.hooks) {
						if (typeof hook === "function") {
							hook(status);
						}
					}
					if (status === "authenticated") {
						console.log("authenticated")
						clearInterval(ctx.interval);
						ctx.interval = null;
						ctx.hooks = [];
					}
				}).catch((err: any) => {
					if (err.error === "TokenStatusError" && err.message === "Invalid token") {
						clearInterval(ctx.interval)
						ctx.tokenErrorHandler(err.error);
					} else {
						console.error(err);
						return ctx.handleError(err as AxiosError);
					}
				});
			} catch (error) {
				console.error(error);
				return ctx.handleError(error as AxiosError);
			}
		};
	}

	public async getStatus(userAgent?: string): Promise<"authenticated" | "waiting-pin" | "created"> {
		if (!this.token) {
			throw new Error("Token is not set");
		}

		try {
			const queryParams = new URLSearchParams();
			if (typeof userAgent === "string") {
				queryParams.set("ua", userAgent);
			}

			const response: AxiosResponse<DeepStatusResponse> = await this.api.post(`/auth/deep/status${queryParams.toString().length > 0 ? `?${queryParams.toString()}` : ""}`, {
				token: this.token,
			});

			if (response.data.stage === "authenticated") {
				this.jwt = response.data.jwt as string;
				window.localStorage.setItem("jwt", this.jwt);
			} else if (response.data.stage === "waiting-pin") {
				this.pin = response.data.pin as string;
			}
			return response.data.stage;
		} catch (error) {
			return this.handleError(error as AxiosError);
		}
	}

	public async sso(client: string, ssoJwt: string, version?: string): Promise<string> {
		try {
			const payload = { jwt: ssoJwt };
			const eClient = encodeURIComponent(client);
			const eVersion = typeof version === "string" ? encodeURIComponent(version) : "";
			const response: AxiosResponse<SsoResponse> = await this.api.post(`/sso/${eClient}/${eVersion}`,
				payload
			);

			this.jwt = response.data.jwt;
			window.localStorage.setItem("jwt", this.jwt);
			return response.data.jwt;
		} catch (error) {
			console.log({error})
			return this.handleError(error as AxiosError);
		}
	}

	public logout() {
		this.token = null;
		this.jwt = null;
		this.pin = null;
		window.localStorage.removeItem("jwt");
		// TODO: handle DB
	}

	public statusRunning(): boolean {
		return this.interval !== null;
	}

	public getJwt(): string | null {
		return this.jwt;
	}

	public getPin(): string | null {
		return this.pin;
	}

	public async verifyPin(): Promise<DeepConfirmResponse> {
		console.log({token: this.token});
		const response: AxiosResponse<DeepConfirmResponse> = await this.api.post("/auth/deep/ssoconfirm",
			{ token: this.token },
			{ headers: {
				Authorization: `Bearer ${this.jwt}`
			}}
		);
		return response.data;
	}

	public async checkInvitation(token: string, secret: string): Promise<InviteResponse> {
		const response: AxiosResponse<InviteResponse> = await this.api.post("/auth/invitation", { token, secret });

		return response.data;
	}
}

const Authentication = new AuthenticationClient();

export { AuthenticationClient, Authentication };
